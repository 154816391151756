
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.wrapper {
    padding: 50px 0;
    @apply mx-auto px-5 py-6 lg:max-w-7xl flex flex-col justify-center md:text-center;

    h2 {
        @apply text-xl mb-4 capitalize;
        color: #000;
        @include md {
            @apply text-2xl;
        }
    }
    p {
        color: $bestearpluglawyers-grey;
        font-weight: 400;
        font-size: 15px;
        line-height: 32px;
        @include md {
            font-size: 18px;
        }
    }
}
